import React from "react"

class Resume extends React.Component {
  componentDidMount() {
    window.location.replace("http://nat.uduwela.com/natalie-uduwela-resume.pdf")
  }

  render() {
    return null
  }
}

export default Resume
